.banner {
    color: white;
    display: flex;
    // background-image: url('./assets/Paris.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    position: relative;
    border-radius: 10px 10px 0 0;
}
.temp {
    font-size: 60px;
    font-weight: bold;
    margin: 0;
}
.condition {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
}
.infoSet {
    display: flex;
}
.city {
    position: relative;
    left: 300px;
    font-size: 30px;
}
.city p {
    margin: 0;
    padding-top: 10px;
    text-transform: capitalize;
}
.underline {
    height: 3px;
    display:block;
    width:30px;
    background-color: white;
    margin: 0 auto;
}
.weatherInfo {
    position: relative;
    left: 30px;
}

.warning {
    position: absolute;
    left: 50%;
    top: -100px;
    font-size: 20px;
    width: 500px;
    font-weight: 500;
    color: black;
}