.App {
    text-align: center;
    width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    vertical-align: middle;
    position: relative;
}
.container {
    width: 100%;
    height: 100%;
    background-image: url('./assets/bg.jpg');
    background-size: cover;
    display: flex;
    align-items: center;
}
